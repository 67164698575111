.footer {
    text-align: center;
    height: 80px;
    font-family: Gilroy-light;
    margin-top: 40px;
}

.poweredBy {
    margin-bottom: 10px;
    font-size: 12px;
}