/*
HomePage CSS
*/
.homepage-container {
    margin-left: 350px;
    padding: 0px;
  }
  
  .card {
    max-width: 20rem;
    margin: 0px;
  }
  
  
  /* Responsive adjustments */
  @media (max-width: 767px) {
  
    /*
  HomePage CSS
  */
    .homepage-container {
      margin-left: 0px;
      /* Adjust according to sidebar width */
    }
  }