/* Width and color of the scrollbar track */
::-webkit-scrollbar {
  width: 8px;
  background-color: #ffffff;
}

/* Styles for the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #495E57;
}

/* Hover styles for the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color:#87918e ;
}

/*Fonts*/
@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../public/Fonts/Gilroy-Bold.ttf') format('truetype'),
}
@font-face {
  font-family: 'Gilroy-Light';
  src: url('../public/Fonts/Gilroy-Light.ttf') format('truetype'),
}
@font-face {
  font-family: 'Gilroy-Medium';
  src: url('../public/Fonts/Gilroy-Medium.ttf') format('truetype'),
}

.slow-spin {
  animation: spin 3s linear infinite; /* Adjust the 2s to make it spin slower or faster */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.alert-container {
  position: fixed;  /* Absolute positioning */
  top: 0;  /* Position at the top */
  left: 50%;  /* Center horizontally */
  transform: translateX(-50%);  /* Adjust for exact centering */
  background-color: #495E57;
  color: white;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 35%;  /* Adjust width as needed */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);  /* Optional shadow for aesthetics */
  opacity: 0;  /* Start as invisible */
  animation: slideDown 0.5s forwards;  /* Animation to slide the alert down */
  z-index: 1000;  /* Ensure the alert is above other elements */
}

@keyframes slideDown {
  0% {
    top: -100px;  /* Start position above the screen */
    opacity: 0;
  }
  100% {
    top: 10px;  /* End position at the top of the screen */
    opacity: 1;
  }
}

.alert-container span {
  font-family: "Gilroy-Bold";
}

.alert-container button {
  color: white;
  background-color: #495E57;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Gilroy-Bold";
}

.alert-container button:hover {
  background-color: #282828;
}

@media (max-width: 768px) {
  .alert-container {
    width: 95%;  
    padding: 10px; 
  }
}
