.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #495E57; 
  width: 30px; 
  height: 30px; 
  border-radius: 50%;
}
@media (max-width: 576px) {
  .carousel-control-prev, .carousel-control-next {
    display: none;
  }
}
