html {
  scroll-behavior: smooth;
}


/* 
Sidebar CSS
*/
.sidebar {
    height: 100vh;
    width: 350px;
    position: fixed;
    top: 0;
    left: 0;
    background: #282828;
    color: white;
    overflow-x: hidden;
    padding-top: 20px;
    align-items: center;
    justify-content: center;
  }

  nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  nav li {
    margin: 0;
    font-family: Gilroy-Bold;
    font-size: 20px;
  }
  
  nav a {
    text-decoration: none;
    color: inherit;
    padding: 5px 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    position: relative;
  overflow: hidden;
  }
  
  nav a:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  nav a.active {
    text-decoration: underline;
  }
  nav a::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #495E57; /* Use the color of the text for the underline */
    transition: width 0.3s ease;
  }
  
  nav a.underline::before {
    width: 100%;
  }

/* Responsive adjustments */
@media (max-width: 767px) {

    /* 
  Sidebar CSS
  */
    .sidebar {
      width: 100%;
      height: auto;
      /* position: relative; */
      padding-top: 0;
      z-index: 1000;

    }
}