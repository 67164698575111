.whatsapp-button {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.whatsapp-button i {
    font-size: 30px;
    color: #fff; /* Assuming your WhatsApp icon is white */
}
@media (max-width: 767px) {
    .whatsapp-button {
        width: 50px;
        height: 50px;
        padding: 8px;
    }

    .whatsapp-button i {
        font-size: 24px;
    }
}